<template>
  <v-dialog
    v-model="dialog"
    persistent
    no-click-animation
    content-class="elevation-0 overflow-hidden"
  >
    <div class="d-flex justify-center">
      <v-progress-circular
        indeterminate
        color="secondary"
        :width="5"
        :size="120"
      ></v-progress-circular>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: "ModalLoading",
  props: {
    dialog: { type: Boolean, required: true },
  },
};
</script>
<style lang="scss" scoped></style>
