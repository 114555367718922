<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <a
          v-bind="attrs"
          class="mt-6 text-h4 text-decoration-underline font-weight-black"
          style="color: inherit"
          v-on="on"
        >
          Cambiar contraseña
        </a>
      </template>
      <v-card class="pa-10 pb-6 pa-md-14 pb-md-8">
        <v-card-title class="mt-2 pa-0">
          <div
            class="font-weight-bold text-h2 text-md-h1"
            style="word-break: break-word"
          >
            Cambiar contraseña
          </div>
          <v-btn
            icon
            :ripple="false"
            absolute
            right
            top
            class="btn-background-transparent hover-close-btn"
            @click="handleDialog"
          >
            <v-icon large color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-8 px-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="currentPassword"
                  :append-icon="
                    show1
                      ? 'mdi-eye secondary--text'
                      : 'mdi-eye-off secondary--text'
                  "
                  outlined
                  dense
                  placeholder="Contraseña actual"
                  :type="show1 ? 'text' : 'password'"
                  :rules="passwordRules"
                  required
                  class="v-input--is-focused secondary--text rounded-lg"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="newPassword"
                  :append-icon="
                    show2
                      ? 'mdi-eye secondary--text'
                      : 'mdi-eye-off secondary--text'
                  "
                  outlined
                  dense
                  placeholder="Contraseña nueva"
                  :type="show2 ? 'text' : 'password'"
                  :rules="passwordRules"
                  required
                  class="v-input--is-focused secondary--text rounded-lg"
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="confirmNewPassword"
                  :append-icon="
                    show2
                      ? 'mdi-eye secondary--text'
                      : 'mdi-eye-off secondary--text'
                  "
                  outlined
                  dense
                  placeholder="Confirmar contraseña nueva"
                  :type="show2 ? 'text' : 'password'"
                  :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                  class="v-input--is-focused secondary--text rounded-lg"
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-column px-0">
          <Button
            text="Guardar"
            block
            class="mb-6"
            aria-label="Guardar contraseña nueva"
            @event="changePassword()"
          />
          <a
            class="text-h4 text-decoration-underline font-weight-black"
            style="color: inherit"
            @click="handleDialog"
          >
            Cerrar
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { mapState } from "vuex";
import { Auth } from "aws-amplify";
import { passwordRules, confirmPasswordRules } from "@/Utils/rules.js";
import { errorMessageCognito } from "@/Utils/awsCognito.js";

export default {
  name: "ModalPasswordUpdate",
  components: {
    Button,
  },
  data: () => ({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    dialog: false,
    show1: false,
    show2: false,
    valid: true,
    passwordRules: passwordRules,
    confirmPasswordRules: confirmPasswordRules,
  }),
  computed: mapState({
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmNewPassword ||
        "Las contraseñas deben coincidir";
    },
    user(state) {
      return state.user;
    },
  }),
  methods: {
    handleAlert({ type, message }) {
      this.$store.dispatch("handleAlert", {
        type: type,
        message: message,
      });
    },
    handleDialog() {
      this.dialog = !this.dialog;
    },
    async changePassword() {
      let currentUser = this.user;
      currentUser = await Auth.currentAuthenticatedUser();
      if (this.$refs.form.validate()) {
        try {
          await Auth.changePassword(
            currentUser,
            this.currentPassword,
            this.newPassword
          );
          this.handleAlert({
            type: "success",
            message: "Cambio de contraseña exitoso",
          });
          this.handleDialog();
        } catch (error) {
          const message = errorMessageCognito(
            error,
            "Error al cambiar contraseña"
          );
          this.handleAlert({
            type: "error",
            message: message,
          });
          console.log(error);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.hover-close-btn:hover .v-icon {
  transform: scale(1.1);
}
</style>
