<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar page="provider" />
    </div>
    <div class="body-container">
      <div v-if="provider && providerLoaded">
        <v-row>
          <v-col cols="12" align="center">
            <PhotoUpload
              :image-url="provider.photoUrl"
              @uploadPictureFile="uploadPictureFile"
            />
          </v-col>
        </v-row>
        <v-form ref="profileForm" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="1" />
            <v-col cols="10" class="d-flex flex-column flex-md-row">
              <h1 class="text-h1">{{ provider.name }}</h1>
              <p class="mt-0 mt-md-4 ml-0 ml-md-3 text-h4">
                <i> Para ver tu perfil personal cambia a </i>
                <a
                  class="pl-0 ml-1 black--text text-decoration-underline font-weight-black"
                  text
                  @click="handleUserMode()"
                >
                  modo cliente</a
                >
              </p>
            </v-col>
          </v-row>

          <!-- User Info -->
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10" class="d-flex justify-center justify-md-start">
              <h2 class="text-h2">Información de usuario</h2>
            </v-col>
            <v-col cols="1"></v-col>

            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="10">
                <v-container class="d-flex flex-column flex-md-row pa-0">
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="userName">
                      <h4 class="text-h3 font-weight-bold">
                        Nombre del usuario
                      </h4>
                    </label>
                    <v-text-field
                      id="userName"
                      v-model="user['name']"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      maxlength="50"
                      counter="50"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-container>
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="userEmail">
                      <h4 class="text-h3 font-weight-bold">
                        Correo electrónico
                      </h4>
                    </label>
                    <v-text-field
                      id="providerEmail"
                      v-model="user['email']"
                      disabled
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      :rules="emailRules"
                    ></v-text-field>
                  </v-container>
                </v-container>
                <v-container class="d-flex flex-column flex-md-row pa-0">
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="userPhone">
                      <h4 class="text-h3 font-weight-bold">Teléfono</h4>
                    </label>
                    <v-text-field
                      id="providerPhone"
                      v-model="user['phone']"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      maxlength="10"
                      counter="10"
                      :rules="phoneRules"
                    ></v-text-field>
                  </v-container>
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="userAdress">
                      <h4 class="text-h3 font-weight-bold">Estado</h4>
                    </label>
                    <v-autocomplete
                      id="providerState"
                      v-model="user['state']"
                      :items="states"
                      auto-select-first
                      outlined
                      dense
                      color="secondary"
                      background-color="transparent"
                      item-color="secondary"
                      no-data-text="Estado no encontrado"
                      :menu-props="{
                        bottom: true,
                        closeOnContentClick: true,
                        allowOverflow: true,
                        offsetY: true,
                        rounded: 'md',
                        transition: 'slide-y-transition',
                      }"
                      autocomplete="null"
                      :rules="stateRulesSelect"
                      class="v-input--is-focused secondary--text rounded-lg"
                      @change="userStateUpdated"
                    />
                  </v-container>
                </v-container>
                <v-container class="d-flex flex-column flex-md-row pa-0">
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="userAdress">
                      <h4 class="text-h3 font-weight-bold">Ciudad</h4>
                    </label>
                    <v-autocomplete
                      id="providerCity"
                      v-model="user['city']"
                      :items="userCitySelect"
                      auto-select-first
                      outlined
                      dense
                      color="secondary"
                      background-color="transparent"
                      item-color="secondary"
                      :no-data-text="
                        user['state']
                          ? 'Ciudad no encontrada'
                          : 'Seleccione un estado'
                      "
                      :menu-props="{
                        bottom: true,
                        closeOnContentClick: true,
                        allowOverflow: true,
                        offsetY: true,
                        rounded: 'md',
                        transition: 'slide-y-transition',
                      }"
                      autocomplete="null"
                      :rules="cityRulesSelect"
                      class="v-input--is-focused secondary--text rounded-lg"
                    />
                  </v-container>
                  <v-container class="mx-md-3 mx-0 pa-0"> </v-container>
                </v-container>
              </v-col>
            </v-row>
          </v-row>
          <v-row class="my-10">
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <h2 class="text-h3 font-weight-bold">Contraseña</h2>
              <div class="d-flex mt-2">
                <span class="ml-2 mr-9">**********</span>
                <ModalPasswordUpdate />
              </div>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <div class="text-h3 font-weight-bold mb-3">
                Sube tus mejores fotos para mostrarlas en tu perfil
              </div>
              <MultImageUpdate
                v-if="providerImages"
                class="d-flex justify-start"
                :no-images="noImages"
                :max="20"
                upload-msg="Haz click o arrastra una imagen al recuadro (max 20 imágenes)"
                max-error="Solamente puede subir hasta 20 imágenes."
                file-error="Solamente se aceptan imágenes (.png o .jpg)"
                :pre-images="providerImages"
                @imagesChanged="updateImages"
              />
            </v-col>
          </v-row>

          <!-- Provider Info -->
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10" class="d-flex justify-center justify-md-start">
              <h2 class="text-h2">Datos de la empresa</h2>
            </v-col>
            <v-col cols="1" />

            <v-row>
              <v-col cols="1" />
              <v-col cols="10">
                <v-container class="d-flex flex-column flex-md-row pa-0">
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="businessName">
                      <h4 class="text-h3 font-weight-bold">
                        Nombre de empresa
                      </h4>
                    </label>
                    <v-text-field
                      id="businessName"
                      v-model="provider.name"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      maxlength="60"
                      counter="60"
                      :rules="fillInput"
                    />
                  </v-container>
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="businessEmail">
                      <h4 class="text-h3 font-weight-bold">Correo</h4>
                    </label>
                    <v-text-field
                      id="businessEmail"
                      v-model="provider.email"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      maxlength="100"
                      :rules="emailRules"
                    />
                  </v-container>
                </v-container>
                <v-container class="d-flex flex-column flex-md-row pa-0">
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="businessPhone">
                      <h4 class="text-h3 font-weight-bold">Teléfono</h4>
                    </label>
                    <v-text-field
                      id="businessPhone"
                      v-model="provider.phone"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      maxlength="10"
                      counter="10"
                      :rules="phoneRules"
                    />
                  </v-container>
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="businessStreet">
                      <h4 class="text-h3 font-weight-bold">Calle</h4>
                    </label>
                    <v-text-field
                      id="businessStreet"
                      v-model="provider.street"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      maxlength="255"
                      :rules="streetRules"
                    />
                  </v-container>
                </v-container>
                <v-container class="d-flex flex-column flex-md-row pa-0">
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="externalNumber">
                      <h4 class="text-h3 font-weight-bold">Número externo</h4>
                    </label>
                    <v-text-field
                      id="externalNumber"
                      v-model="provider.externalNumber"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      color="purple"
                      :rules="externalAddressNumberRules"
                    />
                  </v-container>

                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="internalNumber">
                      <h4 class="text-h3 font-weight-bold">Número interno</h4>
                    </label>
                    <v-text-field
                      id="internalNumber"
                      v-model="provider.internalNumber"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      :rules="optionalInternalAddressNumberRules"
                    />
                  </v-container>
                </v-container>
                <v-container class="d-flex flex-column flex-md-row pa-0">
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="businessNeighbour">
                      <h4 class="text-h3 font-weight-bold">Colonia</h4>
                    </label>
                    <v-text-field
                      id="businessNeighbour"
                      v-model="provider.district"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      color="purple"
                      maxlength="255"
                      :rules="neighborRules"
                    />
                  </v-container>

                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="userPostalCode">
                      <h4 class="text-h3 font-weight-bold">Código postal</h4>
                    </label>
                    <v-text-field
                      id="businessPostalCode"
                      v-model="provider.zipCode"
                      outlined
                      class="v-input--is-focused secondary--text rounded-lg"
                      dense
                      maxlength="5"
                      counter="5"
                      :rules="postalCodeRules"
                    />
                  </v-container>
                </v-container>

                <v-container class="d-flex flex-column flex-md-row pa-0">
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="businessState">
                      <h4 class="text-h3 font-weight-bold">Estado</h4>
                    </label>
                    <v-autocomplete
                      id="businessState"
                      v-model="provider.state"
                      :items="states"
                      auto-select-first
                      outlined
                      dense
                      color="secondary"
                      background-color="transparent"
                      item-color="secondary"
                      no-data-text="Estado no encontrado"
                      :menu-props="{
                        bottom: true,
                        closeOnContentClick: true,
                        allowOverflow: true,
                        offsetY: true,
                        rounded: 'md',
                        transition: 'slide-y-transition',
                      }"
                      autocomplete="null"
                      :rules="stateRulesSelect"
                      class="v-input--is-focused secondary--text rounded-lg"
                      @change="providerStateUpdated"
                    />
                  </v-container>
                  <v-container class="mx-md-3 mx-0 pa-0">
                    <label for="businessCity">
                      <h4 class="text-h3 font-weight-bold">Ciudad</h4>
                    </label>
                    <v-autocomplete
                      id="businessCity"
                      v-model="provider.city"
                      :items="providerCitySelect"
                      auto-select-first
                      outlined
                      dense
                      color="secondary"
                      background-color="transparent"
                      item-color="secondary"
                      :no-data-text="
                        provider.state
                          ? 'Ciudad no encontrada'
                          : 'Seleccione un estado'
                      "
                      :menu-props="{
                        bottom: true,
                        closeOnContentClick: true,
                        allowOverflow: true,
                        offsetY: true,
                        rounded: 'md',
                        transition: 'slide-y-transition',
                      }"
                      autocomplete="null"
                      :rules="cityRulesSelect"
                      class="v-input--is-focused secondary--text rounded-lg"
                    />
                  </v-container>
                </v-container>
              </v-col>
            </v-row>
          </v-row>

          <v-row>
            <v-col cols="1" />
            <v-col cols="10" class="px-0 px-md-2">
              <label for="providerDescription">
                <h4 class="text-h3 font-weight-bold">Descripción</h4>
              </label>
              <v-textarea
                id="providerDescription"
                v-model="provider.description"
                class="v-input--is-focused secondary--text rounded-lg"
                placeholder="Escribe sobre tí para que te conozcan los proveedores."
                rows="4"
                outlined
                dense
                auto-grow
                maxlength="255"
                counter="255"
                :rules="descriptionRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1" />
            <v-col cols="10" sm="6">
              <h2 class="text-h3 font-weight-bold">
                Identificación del patrón
              </h2>
              <FileUploadProfile
                class="mt-2"
                :btn-color="btnColor"
                :file-name="idCardName"
                @uploadFile="uploadIdFile"
              />
            </v-col>
          </v-row>

          <!-- Stripe Info. -->
          <v-row>
            <v-col cols="1" />
            <v-col cols="10">
              <h2 class="text-h2">Datos de cuenta Stripe</h2>
            </v-col>
            <v-col cols="1" />

            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="10">
                <v-container
                  v-if="stripeInfoFetched && provider.stripeMail"
                  class="d-flex flex-column flex-md-row pa-0"
                >
                  <v-container class="mx-3 pa-0">
                    <div class="text-h3 font-weight-bold">Correo</div>
                    <div class="mt-3 text-h4">
                      {{ provider.stripeMail }}
                    </div>
                  </v-container>
                  <v-container class="mx-3 mt-3 mt-md-0 pa-0">
                    <div class="text-h3 font-weight-bold">
                      Cuenta registrada en Stripe
                    </div>
                    <div class="mt-3 text-h4">
                      {{ provider.stripeLastFourDigits }}
                    </div>
                  </v-container>
                </v-container>
                <v-container
                  v-if="
                    !fetchingStripeInfo &&
                    stripeInfoFetched &&
                    provider.stripeMail &&
                    !provider.stripeChargeEnable
                  "
                  class="mx-3 mt-5 pa-0"
                >
                  <span class="py-0">
                    No has terminado el registro de tu empresa en Stripe, da
                    <a
                      class="font-weight-bold text-decoration-underline"
                      :class="loading && 'grey--text'"
                      style="color: inherit"
                      @click="!loading && redirectStripe()"
                    >
                      click aquí</a
                    >
                    para continuar.
                  </span>
                </v-container>
                <v-container
                  v-if="
                    !fetchingStripeInfo &&
                    stripeInfoFetched &&
                    !provider.stripeMail
                  "
                  class="mx-3 pa-0"
                >
                  <div class="py-0 mr-3 mb-4 text-h4">
                    Para poder aceptar solicitudes como proveedor debes de
                    registrarte en la plataforma de pagos seguros Stripe.
                  </div>
                  <Button
                    text="Iniciar registro"
                    outlined
                    :horizontal-padding="6"
                    :disabled="loading"
                    aria-label="Ir a registro de cuenta de Stripe"
                    @event="redirectStripe()"
                  />
                </v-container>
              </v-col>
            </v-row>
          </v-row>
          <br />
          <br />

          <!-- Notifications -->
          <v-row class="mb-16">
            <!-- <v-col cols="1"></v-col>
        <v-col cols="10">
          <h2>Notificaciones</h2>
        </v-col>
        <v-col cols="1"></v-col>

        <v-col cols="1"></v-col>
        <v-col cols="10" sm="5" class="checkboxStyles">
          <v-checkbox
            id="emailNotificationCheck"
            v-model="checkboxEmail"
            color="secondary"
style="color: secondary"
          >
            <template v-slot:label>
              Recibir notificaciones por correo
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="1" class="hidden-sm-and-up"></v-col>

        <v-col cols="1" class="hidden-sm-and-up"></v-col>
        <v-col cols="10" sm="5" class="checkboxStyles">
          <v-checkbox
            id="quotationNotificationCheck"
            v-model="checkboxQuotation"
            color="secondary"
style="color: secondary"
          >
            <template v-slot:label>
              Recibir notificaciones por cada solicitud de cotizacion
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="1"></v-col>

        <v-col cols="1"></v-col>
        <v-col cols="10" sm="5" class="checkboxStyles">
          <v-checkbox
            id="emailNotificationCheck"
            v-model="checkboxEmailNotification"
            color="secondary"
style="color: secondary"
          >
            <template v-slot:label>
              Recibir notificaciones por correo
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="1" class="hidden-sm-and-up"></v-col>

        <v-col cols="1" class="hidden-sm-and-up"></v-col>
        <v-col cols="10" sm="5" class="checkboxStyles">
          <v-checkbox
            id="quotationNotificationCheck"
            v-model="checkboxEachQuotation"
            color="secondary"
style="color: secondary"
          >
            <template v-slot:label>
              Recibir notificaciones por cada solicitud de cotizacion
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="1" class="hidden-sm-and-up"></v-col> -->
            <v-col cols="2" sm="7" md="6"></v-col>
            <v-col cols="10" sm="5" class="checkboxStyles">
              <div
                class="d-flex justify-center justify-md-end mx-md-3 mx-0 pa-0"
              >
                <Button
                  text="Guardar cambios"
                  aria-label="Guardar cambios"
                  @event="submitInfo()"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
    <ModalLoading :dialog="loading" />
    <Footer />
  </v-container>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import PhotoUpload from "@/components/Shared/PhotoUpload.vue";
import FileUploadProfile from "@/components/Provider/Shared/FileUploadProfile.vue";
import MultImageUpdate from "@/components/Shared/MultImageUpdate.vue";
import ModalPasswordUpdate from "@/components/Shared/ModalPasswordUpdate.vue";
import Button from "@/components/Shared/Button.vue";
import Footer from "@/components/Shared/Footer.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import { mapState } from "vuex";
import { states, getCitiesByState } from "@/Utils/locations.js";
import { useQuery } from "@/graphql/index";
import {
  GET_STRIPE_URL_REGISTER,
  GET_PROVIDER_IMAGES,
} from "@/graphql/queries";
import {
  nameRules,
  stateRulesSelect,
  cityRulesSelect,
  cityRules,
  streetRules,
  emailRules,
  phoneRules,
  postalCodeRules,
  neighborRules,
  fillInput,
  descriptionRules,
  externalAddressNumberRules,
  optionalInternalAddressNumberRules,
} from "@/Utils/rules";

export default {
  name: "ProviderProfile",
  components: {
    Navbar,
    PhotoUpload,
    FileUploadProfile,
    MultImageUpdate,
    ModalPasswordUpdate,
    Button,
    Footer,
    ModalLoading,
    InlineLoading,
  },
  emits: ["profilePicUpdated"],
  data: () => ({
    dialog: false,
    checkbox: true,
    btnColor: "secondary",
    idCardName: "Identificación",
    idCardStatus: true,
    fileType: "",
    loading: false,
    stripeInfoFetching: false,
    stripeInfoFetched: false,
    fetchingStripeInfo: false,
    valid: false,
    states: states,
    nameRules: nameRules,
    stateRulesSelect: stateRulesSelect,
    cityRulesSelect: cityRulesSelect,
    cityRules: cityRules,
    streetRules: streetRules,
    emailRules: emailRules,
    phoneRules: phoneRules,
    postalCodeRules: postalCodeRules,
    externalAddressNumberRules: externalAddressNumberRules,
    optionalInternalAddressNumberRules: optionalInternalAddressNumberRules,
    neighborRules: neighborRules,
    fillInput: fillInput,
    descriptionRules: descriptionRules,
    providerLoaded: false,
    providerImages: null,
    noImages: false,
    providerImagesFetched: false,
  }),
  computed: mapState({
    provider(state) {
      if (state.provider) {
        this.providerLoaded = true;
        return state.provider;
      } else {
        this.providerLoaded = false;
        return { photoUrl: null };
      }
    },
    user(state) {
      if (state.user) {
        return state.user;
      } else {
        return { photo: null };
      }
    },
    userCitySelect() {
      return getCitiesByState(this.user.state);
    },
    providerCitySelect() {
      return getCitiesByState(this.provider.state);
    },
  }),
  watch: {
    provider: {
      async handler(provider) {
        if (!provider.id) {
          this.providerLoaded = false;
          return;
        }
        if (!this.providerImagesFetched) {
          this.getProviderImages();
          this.providerImagesFetched = true;
        }
        if (!this.stripeInfoFetching && !this.stripeInfoFetched) {
          this.stripeInfoFetching = true;
          this.providerLoaded = false;
          await this.fetchStripeInfo(provider.id);
          this.providerLoaded = true;
          this.stripeInfoFetched = true;
          this.stripeInfoFetching = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    providerStateUpdated() {
      this.provider.city = "";
    },
    userStateUpdated() {
      this.user.city = "";
    },
    handleUserMode() {
      this.$router.push({ name: "UserProfile" });
    },
    handleAlert({ type, message }) {
      this.$store.dispatch("handleAlert", {
        type: type,
        message: message,
      });
    },
    async getProviderImages() {
      const { data, errors } = await useQuery(GET_PROVIDER_IMAGES, {
        providerId: parseInt(this.provider.id),
      });
      if (data) {
        this.providerImages = data.providerImages;
      } else if (errors) {
        console.log(errors);
      }
    },
    updateImages(images) {
      this.noImages = images.images.length == 0;
      this.provider.images = images;
    },
    async submitInfo() {
      this.noImages = this.provider.images.images.length == 0;
      if (this.$refs.profileForm.validate() && !this.noImages) {
        this.loading = true;
        this.fetchingStripeInfo = true;
        const formattedImages = this.provider.images.images.map((image) => ({
          base64: image.fileBase64,
          name: image.filename,
        }));
        const providerWithFormattedImages = {
          ...this.provider,
          images: formattedImages,
        };
        await this.$store.dispatch(
          "updateProvider",
          providerWithFormattedImages
        );
        await this.$store.dispatch("updateUserInfo", this.user);
        await this.fetchStripeInfo(this.provider.id);
        this.fetchingStripeInfo = false;
        this.loading = false;
        this.$router.go(this.$router.currentRoute);
      }
    },
    async uploadPictureFile(file) {
      this.loading = true;
      this.fetchingStripeInfo = true;
      await this.$store.dispatch("updateProviderPictureProfile", file);
      await this.fetchStripeInfo(this.provider.id);
      this.fetchingStripeInfo = false;
      this.loading = false;
    },
    async uploadIdFile(file) {
      this.loading = true;
      this.fetchingStripeInfo = true;
      await this.$store.dispatch("updateUserCardId", file);
      await this.fetchStripeInfo(this.provider.id);
      this.fetchingStripeInfo = false;
      this.loading = false;
    },
    async fetchStripeInfo(providerId) {
      if ((await providerId) == undefined) return;
      await this.$store.dispatch("retrieveProviderStripeInformation", {
        providerId: parseInt(providerId),
      });
    },
    async redirectStripe() {
      this.loading = true;
      const { data, errors } = await useQuery(GET_STRIPE_URL_REGISTER, {
        stripe_id: this.provider.stripeConnectedAccount,
        return_url: process.env.VUE_APP_STRIPE_URL + "proveedor/perfil",
        refresh_url: process.env.VUE_APP_STRIPE_URL + "proveedor/",
      });
      if (data) {
        window.location.replace(data.getStripeUrlRegister.url);
      } else if (errors) {
        this.handleAlert({
          type: "error",
          message: "Error durante la conexión con Stripe. Vuelve a intentarlo.",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: black !important;
}
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
</style>
