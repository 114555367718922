<template>
  <div>
    <v-btn outlined class="rounded-pill btn-background-purple">
      <span
        text
        :color="btnColor"
        depressed
        dark
        :ripple="false"
        :height="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 45 : 49"
        width="100%"
        class="text-none rounded-md text-h4"
        :loading="isSelecting"
      >
        {{ fileName }}
        <span
          align="start"
          class="flex-nowrap rounded-circle mb-10 ml-3 remove-file"
          text
          height="40px"
          width="40px"
          @click="onChangeStatus"
        >
          <v-icon color="secondary">mdi-close</v-icon></span
        >
      </span>
    </v-btn>

    <input
      ref="uploader"
      :accept="fileType"
      class="d-none"
      type="file"
      @change="onFileChanged"
    />
  </div>
</template>

<script>
import { fileToBase64 } from "@/Utils/Utils";
export default {
  props: {
    fileType: {
      type: String,
      default: "",
    },
    btnColor: {
      type: String,
      default: "",
    },
    widthChange: {
      type: String,
      default: "200px",
    },
    fileName: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    isSelecting: false,
    thereIsFiles: true,
  }),
  methods: {
    onChangeStatus() {
      this.thereIsFiles = false;
      this.$refs.uploader.click();
    },
    async onFileChanged(event) {
      const files = event.target.files;
      let fileName = files[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        return alert("Favor de subir un archivo valido");
      }
      let stringFile = await fileToBase64(files[0], false);
      this.$emit("uploadFile", {
        fileBase64: stringFile,
        filename: fileName,
      });
      this.thereIsFiles = true;
    },
  },
};
</script>

<style scoped>
.remove-file {
  cursor: pointer;
}
.btn-background-purple {
  background-color: rgba(98, 37, 130, 0.1) !important;
}
</style>
