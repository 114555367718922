<template>
  <div
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="drop($event)"
  >
    <div v-show="dropped == 2" class="drop"></div>
    <!-- Error Message -->
    <div v-show="error" class="error">
      {{ error }}
    </div>
    <!-- To inform user how to upload image -->
    <div v-show="images.length == 0 && !loading" class="beforeUpload">
      <input
        ref="uploadInput"
        type="file"
        style="z-index: 1"
        accept="image/*"
        multiple
        @change="previewImgs"
      />
      <svg
        style="fill: white"
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>Upload Image</title>
        <g id="Upload_Image" data-name="Upload Image">
          <g id="_Group_" data-name="&lt;Group&gt;">
            <g id="_Group_2" data-name="&lt;Group&gt;">
              <g id="_Group_3" data-name="&lt;Group&gt;">
                <circle
                  id="_Path_"
                  data-name="&lt;Path&gt;"
                  cx="18.5"
                  cy="16.5"
                  r="5"
                  :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
                />
              </g>
              <polyline
                id="_Path_2"
                data-name="&lt;Path&gt;"
                points="16.5 15.5 18.5 13.5 20.5 15.5"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
              <line
                id="_Path_3"
                data-name="&lt;Path&gt;"
                x1="18.5"
                y1="13.5"
                x2="18.5"
                y2="19.5"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
            </g>
            <g id="_Group_4" data-name="&lt;Group&gt;">
              <polyline
                id="_Path_4"
                data-name="&lt;Path&gt;"
                points="0.6 15.42 6 10.02 8.98 13"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
              <polyline
                id="_Path_5"
                data-name="&lt;Path&gt;"
                points="17.16 11.68 12.5 7.02 7.77 11.79"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
              <circle
                id="_Path_6"
                data-name="&lt;Path&gt;"
                cx="8"
                cy="6.02"
                r="1.5"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
              <path
                id="_Path_7"
                data-name="&lt;Path&gt;"
                d="M19.5,11.6V4A1.5,1.5,0,0,0,18,2.5H2A1.5,1.5,0,0,0,.5,4V15A1.5,1.5,0,0,0,2,16.5H13.5"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
            </g>
          </g>
        </g>
      </svg>

      <p class="mainMessage" align="center">
        {{
          uploadMsg
            ? uploadMsg
            : "Haga click para cargar o soltar sus imágenes aquí"
        }}
      </p>
      <p v-if="noImages" class="red--text" align="center">
        Debe subir al menos una imagen
      </p>
    </div>
    <div v-show="images.length > 0 || loading" class="imgsPreview">
      <div v-for="(img, i) in images" :key="i" class="imageHolder">
        <v-img :src="img" aspect-ratio="1">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-1"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <span class="delete" style="color: white" @click="deleteImg(--i)">
          <b><v-icon>mdi-window-close</v-icon></b>
        </span>
        <div
          v-if="++i == images.length"
          v-show="images.length < max"
          class="plus rounded-circle"
          @click="append"
        >
          +
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fileToBase64, urlToFile } from "@/Utils/Utils";
export default {
  name: "MultImageUpdate",
  props: {
    noImages: { type: Boolean, default: false },
    max: { type: Number, default: 0 },
    uploadMsg: { type: String, default: "" },
    maxError: { type: String, default: "" },
    fileError: { type: String, default: "" },
    colorBorders: {
      type: String,
      default: "#303c42",
    },
    preImages: { type: Array, default: null },
  },
  emits: ["imagesChanged"],
  data() {
    return {
      error: "",
      files: [],
      bases64: [],
      dropped: 0,
      images: [],
      loading: false,
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    preImages: {
      async handler(preImages) {
        if (!preImages) return;
        this.loading = await true;
        await this.previewPreImgs(preImages);
        this.loading = await false;
      },
      immediate: true,
    },
  },
  methods: {
    dragOver() {
      this.dropped = 2;
    },
    dragLeave() {},
    drop(e) {
      let status = true;
      let files = Array.from(e.dataTransfer.files);
      if (e && files) {
        files.forEach((file) => {
          if (file.type.startsWith("image") === false) status = false;
        });
        if (status == true) {
          if (
            this.$props.max &&
            files.length + this.files.length > this.$props.max
          ) {
            this.error = this.$props.maxError
              ? this.$props.maxError
              : `El máximo de archivos es` + this.$props.max;
          } else {
            this.files.push(...files);
            this.previewImgs();
          }
        } else {
          this.error = this.$props.fileError
            ? this.$props.fileError
            : `Tipo de archivo no soportado`;
        }
      }
      this.dropped = 0;
    },
    append() {
      this.$refs.uploadInput.click();
    },
    deleteImg(index) {
      this.images.splice(index, 1);
      this.files.splice(index, 1);
      const imagesBase64 = this.splitImageURLs(this.images);
      this.$emit("imagesChanged", {
        images: imagesBase64,
      });
      this.$refs.uploadInput.value = null;
    },
    async previewPreImgs(preImages) {
      let readers = [];
      for (let i = 0; i < preImages.length; i++) {
        readers.push(`data:image/jpeg;base64,${preImages[i].base64}`);
        const file = await urlToFile(
          `data:image/jpeg;base64,${preImages[i].base64}`,
          preImages[i].name
        );
        this.files.push(file);
        this.bases64.push(preImages[i].base64);
      }

      let imagesBase64 = [];
      Promise.all(readers).then((values) => {
        this.images = values;
        imagesBase64 = this.splitImageURLs(values);
        this.$emit("imagesChanged", {
          images: imagesBase64,
        });
      });
    },
    async previewImgs(event) {
      if (
        this.$props.max &&
        event &&
        event.currentTarget.files.length + this.files.length > this.$props.max
      ) {
        this.error = this.$props.maxError
          ? this.$props.maxError
          : `El máximo de archivos es` + this.$props.max;
        return;
      }
      if (this.dropped == 0) this.files.push(...event.currentTarget.files);
      this.error = "";

      let readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i++) {
        readers.push(fileToBase64(this.files[i]));
      }

      let imagesBase64 = [];
      Promise.all(readers).then((values) => {
        this.images = values;
        imagesBase64 = this.splitImageURLs(values);
        this.$emit("imagesChanged", {
          images: imagesBase64,
        });
      });
    },
    reset() {
      this.$refs.uploadInput.value = null;
      this.images = [];
      this.files = [];
    },
    splitImageURLs(images) {
      let imagesBase64 = [];

      for (let i = 0; i < this.images.length; i++) {
        let imageString = images[i].split(",")[1];
        imagesBase64.push({
          fileBase64: imageString,
          filename: this.files[i].name,
        });
      }

      return imagesBase64;
    },
  },
};
</script>

<style scoped>
.drop {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  position: absolute;
  background-color: #f4f6ff;
  left: 0;
  border: 3px dashed #a3a8b1;
}
.error {
  text-align: center;
  font-size: 15px;
}
.beforeUpload {
  position: relative;
  text-align: center;
}
.beforeUpload input {
  width: 100%;
  margin: auto;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: red;
  display: block;
}
.beforeUpload .icon {
  width: 150px;
  margin: auto;
  display: block;
}
.imgsPreview .imageHolder {
  width: 120px;
  height: 120px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 5px 5px;
  display: inline-block;
}
.imgsPreview .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imgsPreview .imageHolder .delete {
  position: absolute;
  top: 4px;
  right: 4px;
}
.imgsPreview .imageHolder .delete:hover,
.plus:hover,
.beforeUpload input:hover {
  cursor: pointer;
}
.imgsPreview .imageHolder .plus {
  font-size: 21pt;
  height: 30px;
  width: 30px;
  text-align: center;
  border: 1px dashed;
  line-height: 26px;
  position: absolute;
  right: -42px;
  bottom: 43%;
}
</style>
